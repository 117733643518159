<template>
	<QuickfoldersManagerContainer v-if="accountingFirmId" />
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'EurexQuickfoldersManager',
	components: {
		QuickfoldersManagerContainer: () => ({
			component: import('@/components/Eurex/QuickfoldersManager/QuickfoldersManagerContainer')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	watch: {
		accountingFirmId: function () {
			if (!this.service.adminGroup()) {
				this.appService.goTo('accounting-firm-settings')
			}
		}
	}
}
</script>
