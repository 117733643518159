// ============================================================================
// GroupService
// --------------
// GroupService module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/GroupApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// ---------
// Internals
// ---------
const Private = {
	selectAccountingFirm: function (accountingFirm) {
		return store.dispatch('group/setSelected', accountingFirm)
	},
	selectDefaultAccountingFirm: function () {
		const accountingFirms = store.state.group.list
		const selectedAccountingFirm = store.state.group.selected
		let accountingFirm = accountingFirms.find(af => af.id == selectedAccountingFirm?.id)
		if (!accountingFirm) {
			accountingFirm = accountingFirms[0]
		}
		return Private.selectAccountingFirm(accountingFirm)
	},
	setAccountingFirmsList: function (list) {
		let promise = store.dispatch('group/setList', list)
		if (promise !== undefined) {
			return promise.then(() => {
				Private.selectDefaultAccountingFirm()
				return list
			})
		}
		return list
	},
	updateStoreAccountingFirm: function (accountingFirm) {
		store.dispatch('group/updateAccountingFirm', accountingFirm)
	}
}

// -------
// Exports
// -------
export default {
	archiveAccountingFirm: function (accountingFirm) {
		const domain = window.location.hostname
		return API.archiveAccountingFirm(domain, accountingFirm.id).then(() => {
			accountingFirm.deleted_at = new Date().toISOString()
			AppEventBus.emit(AppEvents.ACCOUNTING_FIRM_DELETED, accountingFirm)
			Private.updateStoreAccountingFirm(accountingFirm)
			return accountingFirm
		})
	},
	createAccountingFirm: function (accountingFirm) {
		const domain = window.location.hostname
		return API.createAccountingFirm(domain, accountingFirm)
			.then(res => res.data.data)
			.then(newAccountingFirm => {
				newAccountingFirm.isAccountant = true
				newAccountingFirm.isAccountantAdmin = true
				newAccountingFirm.isGroupAdmin = true
				AppEventBus.emit(AppEvents.ACCOUNTING_FIRM_CREATED, newAccountingFirm)
				store.dispatch('group/addAccountingFirm', newAccountingFirm)
				Private.selectAccountingFirm(newAccountingFirm)
				return newAccountingFirm
			})
	},
	getAccountingFirms: function () {
		const domain = window.location.hostname
		return API.getAccountingFirms(domain)
			.then(res => res.data.data)
			.then(accountingFirms => {
				return Private.setAccountingFirmsList(accountingFirms)
			})
	},
	permanentlyDeleteAccountingFirm: function (accountingFirm) {
		const domain = window.location.hostname
		return API.permanentlyDeleteAccountingFirm(domain, accountingFirm.id).then(() => {
			store.dispatch('group/deleteAccountingFirm', accountingFirm).then(() => {
				if (store.state.group.selected?.id === accountingFirm.id) {
					Private.selectDefaultAccountingFirm()
				}
			})
		})
	},
	restoreAccountingFirm: function (accountingFirm) {
		const domain = window.location.hostname
		return API.restoreAccountingFirm(domain, accountingFirm.id)
			.then(res => res.data.data)
			.then(accountingFirmRes => {
				AppEventBus.emit(AppEvents.ACCOUNTING_FIRM_CREATED, accountingFirmRes)
				Private.updateStoreAccountingFirm(accountingFirmRes)
				return accountingFirmRes
			})
	},
	selectAccountingFirm: Private.selectAccountingFirm,
	updateAccountingFirm: function (accountingFirm) {
		const domain = window.location.hostname
		return API.updateAccountingFirm(domain, accountingFirm.id, accountingFirm)
			.then(res => res.data.data)
			.then(updatedAccountingFirm => {
				AppEventBus.emit(AppEvents.ACCOUNTING_FIRM_UPDATED, updatedAccountingFirm)
				Private.updateStoreAccountingFirm(updatedAccountingFirm)
				return updatedAccountingFirm
			})
	},
	getApplications: function () {
		const domain = window.location.hostname
		return API.getApplications(domain).then(res => res.data.data)
	},
	updateApplicationAssignment: function (appId, isActive) {
		const accountingFirmId = store.state.group.selected?.id
		const groupId = window.location.hostname
		return API.updateApplicationAssignment(groupId, accountingFirmId, appId, { is_active: isActive }).then(res => res.data.data)
	},
	getModules: function () {
		const domain = window.location.hostname
		return API.getModules(domain).then(res => res.data.data)
	},
	updateModuleAssignment: function (moduleId, isActive, parameters = null) {
		const accountingFirmId = store.state.group.selected?.id
		const groupId = window.location.hostname
		return API.updateModuleAssignment(groupId, accountingFirmId, moduleId, { is_active: isActive, parameters: parameters }).then(res => res.data.data)
	},
	adminGroup: function () {
		const accountingFirm = store.state.group.selected
		return accountingFirm.isGroupAdmin
	}
}
