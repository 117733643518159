// ============================================================================
// QuickFoldersService
// -------------------
// QuickFolders module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/QuickFoldersApi'
import { Bus as BUS, Events as EVENTS } from '@/events/QuickFolders/QuickFoldersEvents'
import { store } from '@/store'

import AppService from '@/services/AppService'

import GroupService from '@/services/Group/GroupService'

// ---------
// Internals
// ---------
const Private = {
	createDocument: function (vendorId, file, destinationId, isOldModule, params = {}) {
		if (isOldModule) {
			return API.createDocumentForOldQuickfolders(vendorId, destinationId, file, params)
		} else {
			const accountingFirmId = store.state.accountingFirm?.selected?.id
			return API.createDocumentForEurexQuickfolders(accountingFirmId, vendorId, destinationId, file)
		}
	}
}

// -------
// Exports
// -------
export default {
	compareValues: AppService.compareValues,
	selectCompany: function (company) {
		AppService.goTo({
			params: {
				vendor_id: company.id
			}
		})
	},
	create: function (vendorId, quickfolderData) {
		return API.create(vendorId, {}, quickfolderData)
	},
	get: function (vendorId) {
		return API.list(vendorId)
	},
	update: function (vendorId, quickfolderId, quickfolderData) {
		return API.modify(vendorId, quickfolderId, {}, quickfolderData)
	},
	destroy: function (vendorId, quickfolderId) {
		return API.delete(vendorId, quickfolderId)
	},
	changeQuickfolderApplication: function (vendorId, quickfolderId, applicationId, isActive) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const data = {
			is_active: isActive
		}
		return API.changeQuickfolderApplication(accountingFirmId, vendorId, quickfolderId, applicationId, {}, data)
	},
	getApplications: function (vendorId, params) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getApplications(accountingFirmId, vendorId, params)
	},
	getMetadatas: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getMetadatas(accountingFirmId)
	},
	getPluginsInQuickfolderModelForm: function () {
		let result = []
		if (AppService.hasModule('everial-ecm')) {
			result.push(() => import('@/components/Everial/QuickfolderModelFormSection'))
		}
		return result
	},
	getQuickfolders: function (vendorId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getQuickfolders(accountingFirmId, vendorId)
	},
	createQuickfolderModel: function (quickfolderModel) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.createQuickfolderModel(accountingFirmId, {}, quickfolderModel).then(createdQuickfolderModel => {
			BUS.emit(EVENTS.QUICKFOLDER_MODEL_CREATED, createdQuickfolderModel)
			return createdQuickfolderModel
		})
	},
	deleteQuickfolderModel: function (quickfolderModelId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.deleteQuickfolderModel(accountingFirmId, quickfolderModelId).then(() => {
			BUS.emit(EVENTS.QUICKFOLDER_MODEL_DELETED, quickfolderModelId)
		})
	},
	getQuickfolderModels: function (accountingFirmId) {
		return API.getQuickfolderModels(accountingFirmId)
	},
	updateQuickfolderModel: function (quickfolderModel) {
		return API.updateQuickfolderModel(store.state.accountingFirm?.selected?.id, quickfolderModel.id, {}, quickfolderModel).then(updatedQuickfolderModel => {
			BUS.emit(EVENTS.QUICKFOLDER_MODEL_UPDATED, updatedQuickfolderModel)
			return updatedQuickfolderModel
		})
	},
	uploadItems: function (vendorId, files, destinationId, isOldModule = true, params = {}) {
		const promises = files.map(file => {
			return Private.createDocument(vendorId, file, destinationId, isOldModule, params)
		})
		return Promise.all(promises)
	},
	adminGroup: GroupService.adminGroup,
	listFolders: function (vendorId, folderId, fields, filters = {}) {
		filters['filters[type]'] = ['folder']
		if (filters?.with_virtual_folders) {
			filters['filters[type]'].push('virtual_folder')
			delete filters.with_virtual_folders
		}
		return API.listFolders(vendorId, folderId, fields, filters).then(entry => {
			entry.forEach(folder => {
				folder.icon = {
					icon: 'folder',
					color: 'primary'
				}
			})
			return entry
		})
	},
	listFolderFlags: function (vendorId, folderId, filters = {}) {
		const realFilters = {}
		if (filters.hasOwnProperty('weight')) {
			realFilters['filters[weight]'] = filters.weight
		}
		return API.listFolderFlags(vendorId, folderId, realFilters)
	},
	reset: function () {
		return store.dispatch('documents/reset')
	}
}
