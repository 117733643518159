import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	OPEN_QUICKFOLDER_MODEL_FORM: 'open-quickfolder-model-form',
	QUICKFOLDER_MODEL_CREATED: 'quickfolder-model-created',
	QUICKFOLDER_MODEL_DELETED: 'quickfolder-model-deleted',
	QUICKFOLDER_MODEL_UPDATED: 'quickfolder-model-updated',
	TASK_RELOADED: 'task-reloaded'
})
