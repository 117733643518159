import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/group/')

/**
 * @api DELETE /group/{accountingFirmDomain}/{accountingFirmId}
 *
 * @param {String} domain
 * @param {Number} accountingFirmId
 */
const archiveAccountingFirm = (domain, accountingFirmId) => {
	const url = `${domain}/${accountingFirmId}`
	return axios.delete(url)
}

/**
 * @api DELETE /group/{accountingFirmDomain}/{accountingFirmId}/delete
 *
 * @param {String} domain
 * @param {Number} accountingFirmId
 */
const permanentlyDeleteAccountingFirm = (domain, accountingFirmId) => {
	const url = `${domain}/${accountingFirmId}/delete`
	return axios.delete(url)
}

/**
 * @api GET /group/{accountingFirmDomain}
 *
 * @param {String} domain
 */
const getAccountingFirms = domain => {
	const url = domain
	return axios.get(url)
}

/**
 * @api POST /group/{accountingFirmDomain}
 *
 * @param {String} domain
 * @param {Object} data
 */
const createAccountingFirm = (domain, data) => {
	const url = domain
	return axios.post(url, data)
}

/**
 * @api POST /group/{accountingFirmDomain}/{accountingFirmId}/restore
 *
 * @param {String} domain
 * @param {Number} accountingFirmId
 */
const restoreAccountingFirm = (domain, accountingFirmId) => {
	const url = `${domain}/${accountingFirmId}/restore`
	return axios.post(url)
}

/**
 * @api PATCH /group/{accountingFirmDomain}/{accountingFirmId}
 *
 * @param {String} domain
 * @param {Number} accountingFirmId
 * @param {Object} data
 */
const updateAccountingFirm = (domain, accountingFirmId, data) => {
	const url = `${domain}/${accountingFirmId}`
	return axios.patch(url, data)
}

/**
 * @api GET /group/{accountingFirmDomain}/applications
 *
 * @param {String} domain
 */
const getApplications = domain => {
	const url = `${domain}/applications`
	return axios.get(url)
}

/**
 * @api PATCH /group/{groupId}/{accountingFirmId}/applications/{appId}
 *
 * @param {String} groupId
 * @param {Number} accountingFirmId
 * @param {Number} appId
 * @param {Object} data
 */
const updateApplicationAssignment = (groupId, accountingFirmId, appId, data) => {
	const url = `${groupId}/${accountingFirmId}/applications/${appId}`
	return axios.patch(url, data)
}

/**
 * @api GET /group/{accountingFirmDomain}/modules
 *
 * @param {String} domain
 */
const getModules = domain => {
	const url = `${domain}/modules`
	return axios.get(url)
}

/**
 * @api PATCH /group/{groupId}/{accountingFirmId}/modules/{moduleId}
 *
 * @param {String} groupId
 * @param {Number} accountingFirmId
 * @param {Number} moduleId
 * @param {Object} data
 */
const updateModuleAssignment = (groupId, accountingFirmId, moduleId, data) => {
	const url = `${groupId}/${accountingFirmId}/modules/${moduleId}`
	return axios.patch(url, data)
}

export default {
	archiveAccountingFirm: archiveAccountingFirm,
	permanentlyDeleteAccountingFirm: permanentlyDeleteAccountingFirm,
	getAccountingFirms: getAccountingFirms,
	createAccountingFirm: createAccountingFirm,
	restoreAccountingFirm: restoreAccountingFirm,
	updateAccountingFirm: updateAccountingFirm,
	getApplications: getApplications,
	updateApplicationAssignment: updateApplicationAssignment,
	getModules: getModules,
	updateModuleAssignment: updateModuleAssignment
}
