import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

// TODO : To be refactored like other GED services.
/**
 * @api POST /api/vendors/{vendorId}/quickfolders
 *
 * @param {number} vendorId
 * @param {object} quickfolderData
 *
 * @return {Promise<object>}
 */
const create = (vendorId, params = {}, quickfolderData) => {
	return Backend.POST(URLS.api.vendors.quickfolders.uri, [vendorId], params, quickfolderData)
}

/**
 * @api PATCH /api/vendors/{vendorId}/quickfolders/{quickfolderId}
 *
 * @param {number} vendorId
 * @param {number} quickfolderId
 * @param {object} quickfolderData
 *
 * @return {Promise<object>}
 */
const modify = (vendorId, quickfolderId, params = {}, quickfolderData) => {
	return Backend.PATCH(URLS.api.vendors.quickfolders.uri, [vendorId, quickfolderId], params, quickfolderData)
}

/**
 * @api GET /api/vendors/{vendorId}/quickfolders
 *
 * @param {number} vendorId
 *
 * @return {Promise<object>}
 */
const list = vendorId => {
	return Backend.GET(URLS.api.vendors.quickfolders.uri, [vendorId])
}

/**
 * @api DELETE /api/vendors/{vendorId}/quickfolders/{quickfolderId}
 *
 * @param {number} vendorId
 * @param {number} quickfolderId
 *
 * @return {Promise<object>}
 */
const destroy = (vendorId, quickfolderId) => {
	return Backend.DELETE(URLS.api.vendors.quickfolders.uri, [vendorId, quickfolderId])
}

/**
 * @api PATCH /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/quickfolders/{quickfolderId}/applications/{applicationId}/access
 *
 * @param {number} accountingFirmId
 * @param {number} vendorId
 * @param {number} quickfolderId
 * @param {number} applicationId
 * @param {object} data
 */
const changeQuickfolderApplication = (accountingFirmId, vendorId, quickfolderId, applicationId, params = {}, data) => {
	return Backend.PATCH(
		URLS.api.accountingFirms.vendors.quickfolders.applications.access.uri,
		[accountingFirmId, vendorId, quickfolderId, applicationId],
		params,
		data
	)
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/applications
 *
 * @param {number} accountingFirmId
 * @param {number} vendorId
 *
 * @return {Promise<object>}
 */
const getApplications = (accountingFirmId, vendorId, params) => {
	return Backend.GET(URLS.api.accountingFirms.vendors.applications.uri, [accountingFirmId, vendorId], params)
}

/**
 * @api GET /api/quickfolders/{accountingFirmId}/metadatas
 *
 * @param {Number} accountingFirmId
 */
const getMetadatas = accountingFirmId => {
	return Backend.GET(URLS.api.quickfolders.metadatas.uri, [accountingFirmId])
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/quickfolders
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 */
const getQuickfolders = (accountingFirmId, vendorId) => {
	return Backend.GET(URLS.api.quickfolders.companies.uri, [accountingFirmId, vendorId])
}

/**
 * @api DELETE /api/quickfolders/{accountingFirmId}/models/{quickfolderModelId}
 *
 * @param {Number} accountingFirmId
 * @param {Number} quickfolderModelId
 */
const deleteQuickfolderModel = (accountingFirmId, quickfolderModelId) => {
	return Backend.DELETE(URLS.api.quickfolders.models.uri, [accountingFirmId, quickfolderModelId])
}

/**
 * @api GET /api/quickfolders/{accountingFirmId}/models
 *
 * @param {Number} accountingFirmId
 */
const getQuickfolderModels = accountingFirmId => {
	return Backend.GET(URLS.api.quickfolders.models.uri, [accountingFirmId])
}

/**
 * @api PATCH /api/quickfolders/{accountingFirmId}/models/{quickfolderModelId}
 *
 * @param {Number} accountingFirmId
 * @param {Number} quickfolderModelId
 * @param {Object} data
 */
const updateQuickfolderModel = (accountingFirmId, quickfolderModelId, params = {}, data) => {
	return Backend.PATCH(URLS.api.quickfolders.models.uri, [accountingFirmId, quickfolderModelId], params, data)
}

/**
 * @api POST /api/quickfolders/{accountingFirmId}/models
 *
 * @param {Number} accountingFirmId
 * @param {Object} data
 */
const createQuickfolderModel = (accountingFirmId, params = {}, data) => {
	return Backend.POST(URLS.api.quickfolders.models.uri, [accountingFirmId], params, data)
}

/**
 * @api POST /api/vendors/{vendorId}/folders/{folderId}/documents
 *
 * @param {number} vendorId
 * @param {number} quickfolderId
 * @param {File} file
 * @param {?object} params
 *
 * @return {Promise<object>}
 */
const createDocumentForOldQuickfolders = (vendorId, folderId, file, params = {}, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) => {
	return Backend.CHUNKUPLOAD(URLS.api.vendors.folders.document.uri, [vendorId, folderId], params, file, doCancelPreviousRequest, doCancelAllOtherRequests)
}

/**
 * @api POST /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/quickfolders/{quickfolderId}/documents
 *
 * @param {number} accountingFirmId
 * @param {number} vendorId
 * @param {number} quickfolderId
 * @param {File} file
 *
 * @return {Promise<object>}
 */
const createDocumentForEurexQuickfolders = (accountingFirmId, vendorId, quickfolderId, file, params = {}) => {
	return Backend.CHUNKUPLOAD(URLS.api.accountingFirms.vendors.quickfolders.documents.uri, [accountingFirmId, vendorId, quickfolderId], params, file)
}

const listFolders = (vendorId, folderIdentifier, fields, filters) => {
	return Backend.GET(URLS.api.vendors.folders.nodes.uri, [vendorId, folderIdentifier], {
		fields,
		...filters
	})
}

const listFolderFlags = (vendorId, folderId, filters) => {
	return Backend.GET(URLS.api.vendors.folders.flags.uri, [vendorId, folderId], filters)
}

export default {
	create,
	modify,
	list,
	delete: destroy,
	changeQuickfolderApplication: changeQuickfolderApplication,
	getApplications: getApplications,
	getMetadatas: getMetadatas,
	getQuickfolders: getQuickfolders,
	deleteQuickfolderModel: deleteQuickfolderModel,
	getQuickfolderModels: getQuickfolderModels,
	updateQuickfolderModel: updateQuickfolderModel,
	createQuickfolderModel: createQuickfolderModel,
	createDocumentForOldQuickfolders: createDocumentForOldQuickfolders,
	createDocumentForEurexQuickfolders: createDocumentForEurexQuickfolders,
	listFolders: listFolders,
	listFolderFlags: listFolderFlags
}
